import * as React from "react";
import {useState} from "react";
//Bootstrap seemingly must be imported BEFORE react-spring (otherwise buttons stop working)
import {FloatingLabel, Form, Button, Container, Col, Row, Modal} from 'react-bootstrap'
//import { Parallax, ParallaxLayer } from '@react-spring/parallax'
import { Helmet } from "react-helmet"

//import {PcbAnimated} from "../components/pcb";

//FIXME -- we need to add keywords to this eventually
//import 'bootstrap/dist/css/bootstrap.min.css'
import "../styles/bootswatch_5_1_3_pulse.css"
import {useRef} from "react";
import logo from "../images/ThingdoneLogoOnly.svg"
import wordmark from "../images/WordMarkPaths.svg"
import "../styles/landing.css";
//import "../styles/global.css";
//import linkedin from "../images/linkedin.svg"
//import github from "../images/github.svg"

//import {PcbAnimated} from "../components/pcb";
import {PcbAnimated} from "../components/pcb2";
import {log_data} from "../components/endpoints";
//const PcbAnimated = React.lazy(()=> import("../components/pcb.js").then(module=>({default:module.PcbAnimated})));
/*const PcbAnimated = React.lazy(()=>{
    return new Promise((resolve)=>{
        setTimeout(()=>import("../components/pcb.js").then(module=>(resolve({default:module.PcbAnimated}))), 5000)
    })
}

);*/

function Parallax(props){
    return (<div style={{position:"relative", overflow:"clip"}}>{props.children}</div>);
}

function ParallaxLayer(props){
    return (<div {...props} style={{...props.style, }}>{props.children}</div>);
}

function ParallaxBackground(props){
    //<div style={{position:"relative", overflow:"hidden"}}>
    return (<div className={"parallax1"}>{props.children}</div>);
}

/*function ParallaxOverlay(props){
    return (<div className={"parallax1"}>{props.children}</div>);
}*/
//import {PcbAnimated} from "../components/pcb"

const ProjectCard = (props) => {
    const textStyle = "";//left?"text-start":"text-end";
    return (
        /*minHeight:"100vh"*/
    <Row style={{paddingTop:"10hv", paddingBottom:"10hv"}} className={"service-row"}>
        {props.left === true?(<Col sm={5} className={"d-none d-md-block"}>
        </Col>):null}
        <Col ss={12} md={7}>
            <div className={"service"} >
                <h2 style={{marginBottom:"0px"}} className={"display-3 "+textStyle}>{props.word}</h2>
                <p className={"lead "+ textStyle}>{props.title}</p>
                {props.children}
            </div>
        </Col>
        {props.left !== true?(<Col sm={5} className={"d-none d-md-block"}>
        </Col>):null}
    </Row>
)};


function ScrollDown(props){
    return(
        <Row className={"text-center"}>
            <div style={{paddingTop:"30px"}}>
                <a title="Scroll to next Section" href={props.target}>
                    <br/>
                    <div className={"arrow down"}/>
                </a>
            </div>
        </Row>
    );
}

const isBrowser = typeof window !== "undefined";

let timeout = null;//Only process it every 10ms...

function parallaxEffectScrollDefer(){
    let dirty = false;
    const parallax = document.querySelector(".parallax1");
    const handleScroll = ()=>{
        //Start the next timeout
        timeout = setTimeout(()=>{
            timeout = null;
            if(dirty){
                handleScroll();
            }
        }, 5);
        let scrollPosition = window.pageYOffset;
        parallax.style.transform = `translateY(${scrollPosition * -0.1}px)`
    }
    if(timeout === null){
        handleScroll();
    }else{
        let dirty = true;
    }
}

const LandingPage = ()=>{
    //Parallax effect
    /*if(isBrowser) {
        window.addEventListener("scroll", function () {
            parallaxEffectScrollDefer();
        })
    }*/
    //const pages = useRef();
    //These try to make it jump to the sections
    /*
    let scrollTimeout = null;
    const scrollHandler = (e)=>{
        if(scrollTimeout !== null){
            clearTimeout(scrollTimeout);
        }
        scrollTimeout = setTimeout(scrollDelayHandler, 300);
        console.log("onScroll",e, pages.current);
    }

    const scrollDelayHandler = (e)=>{
        scrollTimeout = null;
        console.log("scrollDelayHandler", pages.current.current, pages.current.space, pages.current.current % pages.current.space)
        //After no scrolling for a time, have it jump to the nearest one IFF it's over
        let current = pages.current.current;
        let space = pages.current.space;
        if (current % space > space*3/4){
            console.log("forcing scroll down");
            pages.current.scrollTo(Math.floor(current / space  + 1));
        }else if(current % space < space / 4){
            pages.current.scrollTo(Math.floor(current / space));
        }
    }

    setTimeout(()=>{
        console.log("scroll installed", pages.current);
        pages.current.container.current.addEventListener("scroll", scrollHandler);
    }, 1);*/
        return (
        <main >
            <Helmet htmlAttributes={{ lang: 'en' }}>
                <meta charSet="utf-8" />
                <title>Thing Done</title>
                <meta name="description" content="Launch your connected product successfully with support from Thing Done LLC." />
                <link rel="canonical" href="http://www.thingdone.com" />
            </Helmet>
            <Parallax pages={4.1} style={{ top: '0', left: '0' }} >
                <ParallaxBackground factor={4} offset={0.7} speed={0.01} style={{zIndex:0, left:"-25vmax"}}>
                    {/*
                    FIXME -- this is bad, suspense is failing to build...
                    <React.Suspense fallback={<div style={{height:"5000vh",width:"100vw", overflow:"ignore"}}></div>}>*/}
                        <PcbAnimated style = {{
                            //width:"200vmax",
                            left: "0",//-150vmax",
                            top: "0",//-25vmax",
                            //left: "-100vh",
                            //zIndex:-20,
                            //height: "auto",
                            //height:"110vmax",
                            //width:"110vw",
                            overflow:"ignore",
                            //display:"flex",
                            //transform: "rotate(45deg)"
                        }} className={"background-pcb"}/>
                    {/*</React.Suspense>*/}
                </ParallaxBackground>
                {/*<ParallaxLayer factor={1.1} offset={0.9} speed={1} style={{ backgroundImage: "linear-gradient(#4c2a8000, #4c2a80, #4c2a80, #4c2a80, #4c2a80, #4c2a80, #4c2a8000)", zIndex:-21 }} />*/}
                {/*Green layer */}
                {/*<ParallaxLayer factor={2.2}  offset={1.9} speed={1} style={{ backgroundImage: "linear-gradient(#002d0400, #002d04, #002d04, #002d04, #002d04, #002d04, #002d04)", zIndex:-21 }} />*/}
                {/*<ParallaxLayer factor={1.1}  offset={2.9} speed={1} style={{ backgroundImage: "linear-gradient(#002d04, #002d04, #002d04, #002d04, #002d04, #002d04, #002d0400)", zIndex:-21 }} />*/}
                {/* Blue layer */}
                {/*<ParallaxLayer factor={1.1}  offset={3.9} speed={1} style={{ backgroundImage: "linear-gradient(#000b4700, #000b47, #000b47, #000b47, #000b47, #000b47, #000b4700)", zIndex:-21 }} />*/}
                <ParallaxLayer
                    offset={0}
                    speed={1}
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection:"Column", height:"110vh"}}
                    className={"first-frame"}>
                    <Container>
                        <Row className={"justify-content-md-center"} >
                            <img width={"90%"} src={wordmark} className={"img-fluid word-mark"} alt={"Thing Done"}/>
                        </Row>
                        <Row className="justify-content-md-center">
                            <Col md={6}>
                                <figure className="text-left match-line">
                                    <p className={"lead"}>Thing Done LLC is the technology consulting firm of Software and Firmware Expert <a href={"https://www.linkedin.com/in/billmccartney/"}>Bill McCartney</a>. We specialize in bringing to market software driven consumer electronics products.
                                </p>
                                </figure>
                            </Col>
                            <Col md={6} className="justify-content-md-center">
                                <figure className="text-left match-line">
                                <p>With decades of experience building and shipping name-brand products, we
                                 get your idea into production. </p>
                                <p>Whether you're looking to supplement your existing team or grow
                                a new team, we have the skills to fill out your product development pipeline.
                                </p>
                                </figure>
                            </Col>

                        </Row>
                        <ScrollDown target={"#services"} />
                    </Container>
                </ParallaxLayer>
                <ParallaxLayer className={"second-frame"}>
                    <Row  className={"justify-content-md-center"}>
                        <Col lg={12}  style={{paddingBottom:"5vh"}}>
                            <h1  style={{ textAlign: "center" }} className={"display-1"} id={"services"}>Services</h1>
                        </Col>
                    </Row>
                </ParallaxLayer>
                <ParallaxLayer
                    offset={1}
                    speed={1}>
                    <Container>
                        <ProjectCard title={"Part Time/Interim Leadership"} word={"Leadership"} left={true}>
                            <p>Many projects need an Leader, but not all the time.
                                We have filled in as CTO/ Technical Leader to organizations.
                                We can help your teams function better.</p>
                        </ProjectCard>
                        <ProjectCard title={"Technical Coaching"} word={"Coaching"} left={false} >
                            <p>If you are introducing a new technology new to a team member or getting a new engineer up to speed, we can coach the team member up to speed quickly and cost effectively.</p>
                            <p>After an initial consult, it turns into regular brief meetings and code reviews.</p>
                        </ProjectCard>
                        <ProjectCard title={"Backend Software and Cloud Management"} word={"Cloud"} left={true} >
                            <p>Built systems on AWS and on premises </p>
                            <p>Rapidly built prototypes</p>
                            <p>Built production clusters handling millions of monthly requests</p>
                            <p>Scaled databases terabytes of images and billions of records</p>
                        </ProjectCard>
                        <ProjectCard title={"Embedded Systems"} word={"Firmware"} left={false} >
                            <p>Whether you are trying to get a project out the door quickly or optimize an existing design for cost or power, we can support many projects directly.</p>
                            <p>bare metal - RTOS - Embedded Linux</p>
                            <p>From 1 year battery life to 1ghz+ high performance systems and everything in between</p>
                        </ProjectCard>
                        <ProjectCard title={"Electronics and Beyond"} word={"Hardware"} left={true} >
                            <p>In electronics, from concept to prototype and beyond</p>
                            <p>Schematic->layout->prototyping</p>
                            <p>Low power design</p>
                            <p>Cost optimization</p>
                            <p>Obsolete part replacement</p>
                        </ProjectCard>
                        <ProjectCard title={"From quantity one to millions "} word={"Production"} left={false} >
                            <p>With over a decade of electronics manufacturing background, we can get and keep products rolling off the lines.</p>
                            <p>We have helped companies deal with part replacements due to supply chain issues, as well as connected production testers for high volume</p>
                        </ProjectCard>
                        <ProjectCard title={"Mobile, Web and Desktop"} word={"Frontend"} left={true} >
                            <p>Worked with Designers and Artist to build elegant, modern interfaces</p>
                            <p>Web based - Since the late 90s and keeping up to date with modern javascript (React) and CSS</p>
                            <p>Experience building mobile apps (Android, iOS and Cross-platform)</p>
                            <p>Desktop Applications (Windows, Linux, Mac)</p>
                        </ProjectCard>
                    </Container>
                </ParallaxLayer>
                <ParallaxLayer className={"second-last-frame"}>
                </ParallaxLayer>
                <ParallaxLayer
                    offset={3}
                    speed={1}
                    className={"last-frame"}
                    style={{
                        //display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minHeight:"100vh"
                    }}>
                    <Container>
                        <Row  className={"justify-content-md-center"}>
                            <Col lg={12}  style={{paddingBottom:"10vh"}}>
                                <h1  style={{ textAlign: "center" }} className={"display-1"}>Getting Started</h1>
                            </Col>
                        </Row>
                        <Row className={"match-line"}>
                            <Col lg={2} className={"d-none d-lg-block"}>
                            </Col>
                            <Col lg={8} md={12}>
                                <p className="lead">Please reach out with your challenges.</p>
                                <p>We’ll start with a quick <mark>free</mark> call about your situation.
                                    Tell us what you’re trying to do and we’ll share how we can help.
                                    At a minimum, we’re happy to give free directions to get you rolling.
                                    And if we don’t have the expertise you need we’ll always tap our deep network to help you connect with someone to help.</p>
                            </Col>
                            <Col lg={2} className={"d-none d-lg-block"}>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12} className={"d-none d-lg-block"} style={{minHeight:"20vh"}}>
                            </Col>
                        </Row>
                        <Row className={"match-line"}>
                            <Col lg={2} className={"d-none d-lg-block"}>
                            </Col>
                            <Col lg={8} md={12}>
                                <ScheduleButtonForm />
                            </Col>
                            <Col lg={2} className={"d-none d-lg-block"}>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12} className={"d-none d-lg-block"} style={{minHeight:"20vh"}}>
                            </Col>
                        </Row>
                        <Row className={"match-line"}>
                            <Col lg={2} className={"d-none d-lg-block"}>
                            </Col>
                            <Col lg={8} md={12}>
                                {/*<p className="lead">Feel free to contact Bill McCartney directly:</p>*/}
                                <p className={"text-center align-text-bottom"}><small>
                                    <a href={"mailto:billmccartney@thingdone.com"}> billmccartney@thingdone.com</a>  +1 440-381-6800
                                </small></p>
                            </Col>
                        </Row>
                    </Container>
                </ParallaxLayer>
            </Parallax>

        </main>
    );
};

function ScheduleButtonForm() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <div className={"d-grid gap-2"}>
                <Button variant="primary" size={"lg"} onClick={handleShow} >
                    Schedule a Call with Bill
                </Button>
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Scheduling Request</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ContactForm onClose={handleClose}  />
                </Modal.Body>
            </Modal>
        </>
    );
}


const regex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
function emailValid(email){
    return regex.test(email) !== false
}
class ContactForm extends React.Component{
    state={email:"",emailInvalid:false, phone:"", nameInvalid:false, note:"", loading:false, sent:false, name:"", formBroken:false, callMethod:"preferZoom", callTime:"preferAfternoon"};
    change = (event)=> {
        console.log(event, event.target.name, event.target.id)
        if(event.target.name === "callMethod") {
            this.setState({callMethod: event.target.id});
        }else if(event.target.name === "callTime"){
            this.setState({callTime: event.target.id});
        }else if(event.target.name === "email"){
            if(!event.target.value || !emailValid(event.target.value)){
                this.setState({[event.target.name]: event.target.value, emailInvalid:true});
            }else{
                this.setState({[event.target.name]: event.target.value, emailInvalid:false});
            }
        }else {
            this.setState({[event.target.name]: event.target.value});
        }
    }
    formSubmit = ()=>{
        this.setState({loading:true, nameInvalid:this.state.name === ""});
        if(this.state.emailInvalid){
            alert("We need an email to get back to you");
            this.setState({loading: false});
        }else{
            setTimeout(()=>{
                let agent = "";
                let timezone = "";
                try{
                    agent = window.navigator.userAgent;
                }catch (e){
                    agent = "UnknownBrowser";
                }
                try{
                    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                }catch(e){
                    timezone = "unknown";
                }
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ email:this.state.email, name:this.state.name, note:this.state.note,
                        phone:this.state.phone, callMethod:this.state.callMethod, callTime:this.state.callTime, agent, timezone})
                };
                console.log("about to fetch");
                //fetch('https://webtest.thingdone.com/mail.php', requestOptions)
                fetch('/mail.php', requestOptions)
                    .then(response => response.json())
                    .then((data)=>{
                        console.log("got back",data);
                        this.setState({loading:false, sent:true});
                    }).catch(()=>{
                        //Error
                        alert("failed to send -- please try again or email us @ billmccartney@thingdone.com");
                        this.setState({loading:false, formBroken:true});
                    });

                console.log("fetch started");
            }, 1000);
        }

    }
    render(){
        if(this.state.sent){
            return (<Container>
                <Row><h3  style={{ textAlign: "center" }} className={"display-3"}>Message Sent</h3></Row>
                <Row>
                    <p className={"lead"}>Thanks! We'll get back to you within 24 hours!</p>
                </Row>
            </Container>)
        }
        if(this.state.formBroken){
            return (<Container>

                <Row><h3  style={{ textAlign: "center" }} className={"display-3"}>Please Email Us</h3></Row>
                <Row>
                    <p className={"lead"}>Sorry our form isn't working. Please email us at <a href={"mailto:billmccartney@thingdone.com"}> billmccartney@thingdone.com</a> and we'll get back to you quickly!</p>
                </Row>
            </Container>)
        }
        return (
            <Form>
                <Form.Group className="sm-3" controlId="formName">
                    <FloatingLabel
                        controlId="floatingName"
                        label="Name"
                        className="sm-3"
                    >
                        <Form.Control name="name" type="name" placeholder="Enter Name" value={this.state.name} onChange={this.change}  />
                    </FloatingLabel>
                </Form.Group>
                <Form.Group className="sm-3" controlId="formEmail">
                    <FloatingLabel
                        controlId="floatingEmail"
                        label="Email address"
                        className="sm-3"
                    >
                        <Form.Control name="email" type="email" placeholder="Enter email" value={this.state.email} onChange={this.change} isInvalid={this.state.emailInvalid}  />
                    </FloatingLabel>
                </Form.Group>
                <Form.Group className="sm-3" controlId="formPhone">
                    <FloatingLabel
                        controlId="floatingPhone"
                        label="Phone"
                        className="sm-3"
                    >
                        <Form.Control name="phone" type="tel" placeholder="Phone Number" value={this.state.phone} onChange={this.change}  />
                    </FloatingLabel>
                </Form.Group>
            <Form.Group className="sm-3" controlId="exampleForm.ControlTextarea1">
                <FloatingLabel
                    controlId="floatingNotes"
                    label="Notes (optional)"
                    className="sm-3"
                >
                    <Form.Control as="textarea" rows={3} name="note" value={this.state.note} onChange={this.change}  />
                </FloatingLabel>
            </Form.Group>
                <div className={"mb-3"} >
                    <Form.Check inline type="radio" id="preferZoom" label="Prefers Zoom" name={"callMethod"} checked={this.state.callMethod === "preferZoom"} onChange={this.change}/>
                    <Form.Check inline type="radio" id="preferPhone" label="Phone call" name={"callMethod"} checked={this.state.callMethod === "preferPhone"} onChange={this.change}/>
                </div>

                <div className={"mb-3"} >
                    <Form.Check inline type="radio" id="preferMorning" label="Prefers Morning(EST)" name={"callTime"} checked={this.state.callTime === "preferMorning"} onChange={this.change}/>
                    <Form.Check inline type="radio" id="preferAfternoon" label="Afternoon" name={"callTime"} checked={this.state.callTime === "preferAfternoon"} onChange={this.change}/>
                    <Form.Check inline type="radio" id="preferEvening" label="Evening" name={"callTime"} checked={this.state.callTime === "preferEvening"} onChange={this.change}/>
                </div>
                <div className={"modal-footer"}>

                {this.state.loading ?
                    (<Button disabled><span className="spinner-border spinner-border-sm" role="status"
                                            aria-hidden="true"/>Please Wait</Button>) :
                    (<><Button variant="secondary" onClick={this.props.onClose}>
                        Close
                    </Button>
                    <Button onClick={this.formSubmit}>Send Message</Button>
                    </>)
                }

                </div>
            <Row>
                <Col sm={12} className={"d-none d-md-block"}>
                </Col>
            </Row>
            <Row>
                <Col sm={12} className={"d-none d-md-block"}>
                </Col>
            </Row>
        </Form>);
    }
}

export default LandingPage